import { useState } from "react";
import {
  Dropdown,
  IDropdownStyles,
  TextField,
  PrimaryButton,
  DialogType,
  Dialog,
  DialogFooter,
  Spinner,
} from "@fluentui/react";
import {
  deviceTypeOptions,
  environmentOptions,
  typeOptions,
  releaseNoteOptions,
} from "./constants";
import "./index.scss";
import {
  DependencyInfo,
  ReleaseStageEnvironment,
  UploadRequestModel,
  ReleaseStages,
} from "./uploadModel";
import customAxios from "../../customAxios";
import { manufacturers } from "../../service/ManufacturerStore";


const FirmwareUpdate = () => {
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

  const [deviceType, setDeviceType] = useState<string | undefined | number>("");
  const [env, setEnv] = useState<string | undefined | number>("");
  const [model, setModel] = useState<string | undefined>("");
  const [manufacturer, setManufacturer] = useState<string | undefined>("");
  const [minFirmwareVer, setMinFirmwareVer] = useState<string | undefined>();
  const [minCompanyPortalVer, setMinCompanyPortalVer] = useState<
    string | undefined
  >();
  const [minAdminAgentVer, setMinAdminAgentVer] = useState<string | undefined>();
  const [minOemVer, setMinOemVer] = useState<string | undefined>();
  const [componentType, setComponentType] = useState<
    string | undefined | number
  >("");
  const [fileName, setFileName] = useState<string | undefined>("");
  const [verCode, setVerCode] = useState<string | undefined>("");
  const [verName, setVerName] = useState<string | undefined>("");
  const [fileUrl, setFileUrl] = useState<string | undefined>("");
  const [digestUrl, setDigestUrl] = useState<string | undefined>("");
  const [releaseNotes, setReleaseNotes] = useState<string | undefined>("");
  const [releaseDesc, setReleaseDesc] = useState<string | undefined>("");
  const [tenantWhitelist, setTenantWhitelist] = useState<string | undefined>("");
  const [dialog, showDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    type: DialogType.normal,
    title: "",
    closeButtonAriaLabel: "Close",
    subText: "",
  });
  const [uploadSpinner, showUploadSpinner] = useState<boolean>(false);
  const [isUploadFailed, setUploadFailed] = useState<boolean>(false);

  const checkTypeInputs = () => {
    if (!!componentType) {
      switch (componentType) {
        case "firmware":
          return true;
        case "teams":
          return !!(minFirmwareVer && minCompanyPortalVer);
        case "companyportal":
          return !!minFirmwareVer;
        case "adminagent":
          return !!(minFirmwareVer && minOemVer && minCompanyPortalVer && minAdminAgentVer);
           case "microsoftintuneapp":
          return !!minFirmwareVer;
          case "authenticatorapp":
            return !!minFirmwareVer;
        default:
          return false;
      }
    }
    return false;
  };

  const getUploadBtnStatus = () => {
    return !(
      deviceType &&
      env &&
      manufacturer &&
      model &&
      componentType &&
      fileName &&
      verCode &&
      verName &&
      fileUrl 
      //making minimum version fields as optional
      //checkTypeInputs()
    );
  };

  const onDialogOK = () => {
    if(!isUploadFailed){
    setDeviceType('');
    setEnv('');
    setModel('');
    setManufacturer('');
    setMinFirmwareVer('');
    setMinCompanyPortalVer('');
    setMinOemVer('');
    setMinAdminAgentVer('')
    setComponentType('');
    setFileName('');
    setVerCode('');
    setVerName('');
    setFileUrl('');
    setDigestUrl('');
    setReleaseNotes('');
    setReleaseDesc('');
    setTenantWhitelist('')
    }
    showDialog(false);
  };

  const setVersionInfo = (versionMap: { [key: string]: string | undefined })=> {
    const dependencyInfo = new DependencyInfo();
    Object.keys(versionMap).forEach((key) => {
      const typedKey = key as keyof DependencyInfo;
      const version = versionMap[key];
      if (version && version.length > 0) {
        dependencyInfo[typedKey] = versionMap[key];
      }
    });
    return dependencyInfo;
  };

  const uploadButtonAction = async () => {
    showUploadSpinner(true);
    setUploadFailed(false);
    const body = new UploadRequestModel();
    body.deviceType = deviceType;
    body.model = model;
    body.manufacturer = manufacturer;
    body.componentType = componentType;
    body.fileName = fileName;
    body.versionCode = verCode;
    body.versionName = verName;
    body.fileUrl = fileUrl;
    body.digestUrl = digestUrl;
    body.releaseNotes = releaseNotes;
    body.description = releaseDesc;
    body.restart = false;

    const tenantList = tenantWhitelist && tenantWhitelist
      .split(",")
      .map(item => item.trim())
      .filter(item => item !== "");

    
    body.tenantWhitelist = tenantList &&  tenantList.length > 0 ? tenantList : [];

    var dependencyInfo;
    
    if (componentType === "firmware" || componentType === "companyportal" || componentType === "microsoftintuneapp" || componentType === "authenticatorapp") {
      dependencyInfo = setVersionInfo({ firmware: minFirmwareVer });
    } else if (componentType === "teams") {
      dependencyInfo= setVersionInfo({ firmware: minFirmwareVer, companyportal: minCompanyPortalVer });
    } else if (componentType === "adminagent") {
      dependencyInfo = setVersionInfo({ firmware: minFirmwareVer, companyportal: minCompanyPortalVer, oem:minOemVer, adminagent: minAdminAgentVer });      
      body.restart = true;
    } 

    if(dependencyInfo != null) {
      body.dependencyInfo = dependencyInfo
    }

    const releaseStages = new ReleaseStages();
    const releaseStageEnv = new ReleaseStageEnvironment();
    releaseStageEnv.publishDate = new Date().toISOString();
    releaseStageEnv.current = true;
    const rawIdToken = sessionStorage.getItem("msal.idtoken");
    if (rawIdToken) {
      const decodedToken = JSON.parse(atob(rawIdToken.split(".")[1]));
      if (decodedToken && decodedToken.name) {
        releaseStageEnv.publishedBy = decodedToken.name;
      }
    }


    if(env === "Test" ) {
      releaseStages.Test = releaseStageEnv;
    } else if (env === "DogFood") {
      releaseStages.DogFood = releaseStageEnv;
    }

    body.releaseStages = releaseStages;    

    await customAxios
      .post("softwarePackages", body)
      .then((response) => {
        setDialogContent({
          ...dialogContent,
          title: "Success",
          subText: 'The Software upload has been queued. Please visit "Software Upload Job Management" section to monitor the status of upload.',
        });
      })
      .catch((error) => {
        setDialogContent({
          ...dialogContent,
          title: "Failed",
          subText: error?.response?.data?.error ? error.response.data.error : 'Failed to upload data. Please try again later.',
        });
      });
      showUploadSpinner(false);
      setUploadFailed(true);
      showDialog(true);
  };
  return (
    <div className="upload-software-container">
      <h2>Upload a new software</h2>
      <h3>
        Please specify the following parameters for uploading a new firmware
      </h3>
      <div className="upload-software-form">
        <Dropdown
          label="Device Type*"
          placeholder="Select a Device Type"
          options={deviceTypeOptions}
          styles={dropdownStyles}
          onChange={(_e, option) => setDeviceType(option?.key)}
        />
        <Dropdown
          label="Release Stage*"
          placeholder="Select the Release Stage"
          options={environmentOptions}
          styles={dropdownStyles}
          onChange={(_e, option)=> setEnv(option?.key)}
          />
        {manufacturers.length > 0 ? (
          <Dropdown
            label="Manufacturer*"
            placeholder="Select the Manufacturer"
            options={manufacturers}
            styles={dropdownStyles}
            onChange={(_e, option) => setManufacturer(option?.key as string)}
          />
        ) : (
          <TextField
            className="textfield"
            label="Manufacturer*"
            placeholder="Enter the Manufacturer"
            onChange={(_e, value) => setManufacturer(value)}
            value={manufacturer}
          />
        )}
        
        <TextField
          className="textfield"
          label="Model*"
          placeholder="Enter the Model"
          onChange={(_e, value) => setModel(value)}
          value={model}
        />
        <Dropdown
          label="Component Type*"
          placeholder="Select a Type"
          options={typeOptions}
          styles={dropdownStyles}
          onChange={(_e, option) => {
            setComponentType(option?.key);
            setMinOemVer("");
            setMinFirmwareVer("");
            setMinCompanyPortalVer("");
          }}
        />
        {componentType && (
          <TextField
            className="textfield"
            label= {componentType !== 'firmware' ? "Minimum Firmware Version": "Minimum Firmware Version"}
            placeholder="Enter the Minimum Firmware Version"
            onChange={(_e, value) => setMinFirmwareVer(value)}
            value={minFirmwareVer}
            type="number"
          />
        )}
        {componentType && componentType === "adminagent" && (
          <TextField
            className="textfield"
            label="Minimum OEM Version"
            placeholder="Enter the Minimum OEM Version"
            onChange={(_e, value) => setMinOemVer(value)}
            value={minOemVer}
            type="number"
          />
        )}
        {componentType && componentType === "adminagent" && (
          <TextField
            className="textfield"
            label="Minimum AdminAgent Version"
            placeholder="Enter the Minimum AdminAgent Version"
            onChange={(_e, value) => setMinAdminAgentVer(value)}
            value={minAdminAgentVer}
            type="number"
          />
        )}
        {componentType &&
          (componentType === "teams" || componentType === "adminagent") && (
            <TextField
              className="textfield"
              label="Minimum Company portal Version"
              placeholder="Enter the Minimum Company portal Version"
              onChange={(_e, value) => setMinCompanyPortalVer(value)}
              value={minCompanyPortalVer}
              type="number"
            />
          )}
        <TextField
          className="textfield"
          label="File Name*"
          placeholder="Enter the File Name"
          onChange={(_e, value) => setFileName(value)}
          value={fileName}
        />
        <TextField
          className="textfield"
          label="Version Code*"
          placeholder="Enter the Version Code"
          onChange={(_e, value) => setVerCode(value)}
          value={verCode}
          type="number"
        />
        <TextField
          className="textfield"
          label="Version Name*"
          placeholder="Enter the Version Name"
          onChange={(_e, value) => setVerName(value)}
          value={verName}
        />
        <TextField
          className="textfield"
          label="Tenant Whitelist"
          placeholder="Enter comma-separated values"
          onChange={(_e, value) => setTenantWhitelist(value)}
          value={tenantWhitelist}
        />
        <Dropdown
          label="Release Notes"
          placeholder="Select Release Note"
          options={releaseNoteOptions}
          styles={dropdownStyles}
          onChange={(_e, option) => setReleaseNotes(option?.key as string)}
        />
        <TextField
          className="textfield"
          label="Release Description"
          multiline ={releaseDesc && releaseDesc.length > 50 ? true : false}
          resizable={false}
          placeholder="Enter the Release Description"
          onChange={(_e, value) => setReleaseDesc(value)}
          value={releaseDesc}
        />
        <TextField
          className="textfield"
          label="File URL*"
          placeholder="Paste the file URL"
          onChange={(_e, value) => setFileUrl(value)}
          value={fileUrl}
        />
        <TextField
          className="textfield"
          label="Digest URL"
          placeholder="Paste the signed digest URL"
          onChange={(_e, value) => setDigestUrl(value)}
          value={digestUrl}
        />
        <PrimaryButton
          className="buttonContainer"
          disabled={getUploadBtnStatus()}
          text="Upload"
          onClick={uploadButtonAction}
        />
        {dialog && (
          <Dialog
            hidden={!dialog}
            onDismiss={() => showDialog(false)}
            dialogContentProps={dialogContent}
            //modalProps={modalProps}
          >
            <DialogFooter>
              <PrimaryButton onClick={onDialogOK} text="Ok" />
            </DialogFooter>
          </Dialog>
        )}
        {uploadSpinner && (
          <Dialog
            hidden={!uploadSpinner}
            onDismiss={(ev) => ev?.preventDefault()}
            dialogContentProps={{
              type: DialogType.normal,
              title: "",
              closeButtonAriaLabel: "Close",
              subText: "This might take a while. Please wait...",
            }}
          >
            <Spinner />
          </Dialog>
        )}
      </div>
      <div className="ring-message"><span >The software can be uploaded in Dogfood or Test ring.</span></div>
    </div>
  );
};

export default FirmwareUpdate;
